<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add AI module</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form class="ma-0 pa-0 row">
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.url"
              label="Url"
              clearable
              outlined
              dense
              :error-messages="urlErrors"
              @input="$v.formData.url.$touch()"
              @blur="$v.formData.url.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12">
            <v-jsoneditor v-model="formData.credentials" :options="options" />
          </div>
          <!-- end address -->
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import VJsoneditor from "v-jsoneditor/src/index";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import {
  required,
  // requiredIf,
  url,
  // numeric,
  // maxLength,
  // between,
  // minLength,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  components: { VJsoneditor },
  validations() {
    return {
      formData: {
        name: { required },
        url: { url },
      },
    };
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    options: { mode: "code", navigationBar: true, mainMenuBar: false },
    dialog: false,
    formData: {
      name: null,
      url: null,
      credentials: {},
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        const data = this.formData;
        ApiService.post("/settings/ai_modules/store", data)
          .then(() => {
            this.pageLoader(false);
            Swal.fire({
              title: "Created",
              text: "New AI module has been added successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        name: null,
        url: null,
        credentials: {},
      };
    },
  },
  computed: {
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    urlErrors() {
      return this.handleFormValidation("url", this);
    },
  },
};
</script>

<style scoped>
.editorStyle {
  max-height: 70vh;
  overflow: hidden;
}
</style>
