<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000px"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit AI Module</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form class="ma-0 pa-0 row">
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.url"
              label="Url"
              clearable
              outlined
              dense
              :error-messages="urlErrors"
              @input="$v.formData.url.$touch()"
              @blur="$v.formData.url.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12">
            <v-jsoneditor v-model="formData.credentials" :options="options" />
          </div>
          <!-- end address -->
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import VJsoneditor from "v-jsoneditor/src/index";

import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  components: { VJsoneditor },
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        name: { required },
        url: { url },
      },
    };
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    options: { mode: "code", navigationBar: true, mainMenuBar: false },
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      name: null,
      url: null,
      credentials: {},
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/settings/ai_modules/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
          this.dialog = false;
        })
        .finally(() => {
          // this.pageLoader(false);
        });
    },

    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        const data = this.formData;
        ApiService.post("/settings/ai_modules/update", data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `AI module has been updated`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.ai_module };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.ai_module).forEach((serverD) => {
            if (localD[0] === serverD[0]) {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          name: null,
          url: null,
          credentials: {},
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetEditForm();
      } else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    urlErrors() {
      return this.handleFormValidation("url", this);
    },
  },
};
</script>

<style scoped>
.editorStyle {
  max-height: 70vh;
  overflow: hidden;
}
</style>
